// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-computer-cheques-index-js": () => import("./../../../src/pages/computer-cheques/index.js" /* webpackChunkName: "component---src-pages-computer-cheques-index-js" */),
  "component---src-pages-computer-forms-index-js": () => import("./../../../src/pages/computer-forms/index.js" /* webpackChunkName: "component---src-pages-computer-forms-index-js" */),
  "component---src-pages-handwritten-forms-index-js": () => import("./../../../src/pages/handwritten-forms/index.js" /* webpackChunkName: "component---src-pages-handwritten-forms-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-product-preview-js": () => import("./../../../src/templates/productPreview.js" /* webpackChunkName: "component---src-templates-product-preview-js" */)
}

